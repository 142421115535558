import React, { useState, useEffect } from "react"
import * as types from "Functions/propTypes"
import { Navbar } from "react-bulma-components"

import { googleLogout } from "Functions/authorize"

import { getUser, useAdministrator } from "Functions/authorize"
import { isNotNullOrEmpty } from "utility"

const Icon = ({ useIcon }) => useIcon ? <img src={useIcon} alt="" /> : null

Icon.propTypes = {
  useIcon: types.string.isRequired
}

const Header = () => {
  const useAdmin = useAdministrator(false)
  const [useIcon, setIcon] = useState("")

  useEffect(() => {
    const f = async () => {
      const user = await getUser() || null
      if (isNotNullOrEmpty(user)) setIcon(user.photoURL)
    }
    f()
  }, [])

  return (
    <Navbar>
      <Navbar.Brand>
        <Navbar.Item><b>LOGO</b></Navbar.Item>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container position="end">
          <Navbar.Item dropdown hoverable>
            <Navbar.Link><Icon useIcon={useIcon} /></Navbar.Link>
            <Navbar.Dropdown right={true}>
              {useAdmin ? <Navbar.Item href="/admin">管理画面</Navbar.Item> : null}
              <Navbar.Item onClick={googleLogout}>ログアウト</Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  )
}

export default Header