import React from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import { Hero, Columns } from "react-bulma-components"
import Index from "Components/Admin/Index"
import News from "Components/Admin/News"
import Group from "Components/Admin/Group"
import User from "Components/Admin/User"

import Auth from "Components/Admin/Auth"

import Header from "Components/Admin/Header"
import Navigation from "Components/Admin/Navigation"

import { URI_LIST } from "constructs"

import "Components/Admin/admin.scss"

const Admin = () => (
  <Auth>
    <Header />
    <Hero color="light" gradient>
      <Columns gapless className="full_height">
        <Columns.Column size={2}>
          <Navigation />
        </Columns.Column>
        <Columns.Column>
          <Switch>
            <Route path={`${URI_LIST.admin.user.index}(/:article)?`}><User /></Route>
            <Route path={`${URI_LIST.admin.group.index}(/:article)?`}><Group /></Route>
            <Route path={`${URI_LIST.admin.news.index}(/:article)?`}><News /></Route>
            <Route exact path="/admin"><Index /></Route>
          </Switch>
        </Columns.Column>
      </Columns>
    </Hero>
  </Auth>
)

export default withRouter(Admin)