import React, { useState } from "react"
import { Columns, Button } from "react-bulma-components"
import { Field, Control, Input } from "Components/General/Form"

const GoogleSearch = () => {
  const [useSearch, setSearch] = useState("")
  const handleOnChange = e => setSearch(e.target.value)

  return (
    <Columns>
      <Columns.Column offset={4} size={4}>
        <form action={`https://www.google.com/search`} target="_blank" className="google_search">
          <Field kind="addons">
            <Control className="google_search_controller">
              <Input
                name="q"
                placeholder="Google検索"
                onChange={handleOnChange}
                value={useSearch}
              />
            </Control>
            <Control>
              <Button color="primary">Search</Button>
            </Control>
          </Field>
        </form>
      </Columns.Column>
    </Columns>
  )
}


export default GoogleSearch