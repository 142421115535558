import React, { useState, useEffect } from "react"
import * as types from "Functions/propTypes"
import { Columns, Content, Modal } from "react-bulma-components"
import Loading from "Components/General/Loading"

import { isNotNullOrEmpty, isNullOrEmpty } from "utility"

const NewsContent = ({ useModal, useContent, setModal }) => {
  return <Modal show={useModal} onClose={() => setModal(false)} closeOnBlur={true} closeOnEsc={true}>
    <Modal.Card>
      <Modal.Card.Head onClose={() => setModal(false)}>
        <Modal.Card.Title className="modal_header_title">{useContent.title}</Modal.Card.Title>
      </Modal.Card.Head>
      <Modal.Card.Body>
        <div className="modal_body">{useContent.body}</div>
      </Modal.Card.Body>
      <Modal.Card.Foot>
        {isNotNullOrEmpty(useContent.url) ? <a href={useContent.url}>{useContent.url}</a> : null}
      </Modal.Card.Foot>
    </Modal.Card>
  </Modal>
}

NewsContent.propTypes = types.NewsContent

const News = ({ useNews }) => {
  const [useModal, setModal] = useState(false)
  const [useContent, setContent] = useState({
    title: "",
    url: "",
    body: "",
    active: true
  })
  const [useLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(isNullOrEmpty(useNews))
  }, [useNews])

  const List = () => (
    useNews.map((news, i) => (
      <li
        key={i}
        onClick={(() => {
          setContent(news.data)
          setModal(true)
        })}
        className="has-text-grey-dark">
        <span className="has-text-link news_list">{news.data.title}</span>
      </li>
    ))
  )

  return (
    <Content>
      <Columns>
        <Columns.Column size={8} offset={2}>
          <div className="news_continaer has-background-white-ter">
            <h3 className="title has-text-grey-dark">会社からのお知らせ</h3>
            <Loading loading={useLoading} />
            <ul>
              <List />
            </ul>
          </div>
        </Columns.Column>
      </Columns>
      <NewsContent
        setModal={setModal}
        useModal={useModal}
        useContent={useContent}
      />
    </Content>
  )
}

News.propTypes = {
  useNews: types.useNews
}

export default News