const firebaseConfig = {
  apiKey: "AIzaSyDs9tPll_1MDcDwmxj-_k0gKE20BTojGCQ",
  authDomain: "organized-portal.firebaseapp.com",
  databaseURL: "https://organized-portal.firebaseio.com",
  projectId: "organized-portal",
  storageBucket: "organized-portal.appspot.com",
  messagingSenderId: "805656550352",
  appId: "1:805656550352:web:3d9b0daefcf62f1056b7d5",
  measurementId: "G-XNH6L3WVCM"
}

export default firebaseConfig