import React from "react"
import * as types from "Functions/propTypes"
import { Redirect, withRouter } from "react-router-dom"

const Auth = ({ useUser, children }) => useUser != null ? children : <Redirect to="/" />

Auth.prototype = {
  useUser: types.useUser.isRequired,
  children: types.children.isRequired
}

export default withRouter(Auth)