import React from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Section, Columns } from "react-bulma-components"
import { InputText, PrimaryButton } from "Components/General/Form"
import ErrorHandling from "Components/General/ErrorHandle"
import { HOSTING_DOMAIN } from "constructs"

import useUserAdd from "Functions/useUserAdd"

const Add = ({ history, title }) => {
  const [useEmail, useError, loading, useHandle, isSubmit, handleOnChange, handleOnSubmit] = useUserAdd(history)
  return (
    <ErrorHandling useHandle={useHandle}>
      <h1 className="title">{title}</h1>
      <p>管理者とするユーザのメールアドレスを入力してください。<br />なお、<strong>{HOSTING_DOMAIN}ドメイン以外は登録できません。</strong></p>
      <Section>
        <Columns>
          <Columns.Column size={7}>
            <form
              onSubmit={handleOnSubmit}
            >
              <InputText
                label="メールアドレス"
                name="email"
                disabled={loading}
                onChange={handleOnChange}
                error={useError}
                value={useEmail}
              />
              <PrimaryButton
                disabled={!isSubmit()}
                loading={loading}
                onClick={handleOnSubmit}
              >登録</PrimaryButton>
            </form>
          </Columns.Column>
        </Columns>
      </Section>
    </ErrorHandling>
  )
}

Add.propTypes = {
  history: types.history,
  title: types.string.isRequired
}

export default withRouter(Add)