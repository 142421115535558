import React from "react"
import * as types from "Functions/propTypes"
import { Link } from "react-router-dom"
import { Form, Button } from "react-bulma-components"
import { isNotNullOrEmpty } from "utility"

export const Field = Form.Field
export const Control = Form.Control
export const Label = Form.Label
export const Input = Form.Input
export const Textarea = Form.Textarea
export const Select = Form.Select
export const Checkbox = Form.Checkbox
export const Radio = Form.Radio
// const Help = Form.Help

const CustomButton = ({ color, to, ...props }) => (
  <Button
    {...props}
    color={isNotNullOrEmpty(color) ? color : null}
    renderAs={isNotNullOrEmpty(to) ? Link : "button"}
    to={isNotNullOrEmpty(to) ? to : null}
  >{props.children}</Button>
)

CustomButton.propTypes = types.CustomButton

const ButtonComponent = ({ isField, ...props }) => (
  isField ? <FieldComponent><CustomButton {...props} /></FieldComponent> : <CustomButton {...props} />
)

ButtonComponent.propTypes = {
  isField: types.bool
}

export const PrimaryButton = props => (
  <ButtonComponent
    {...props}
    color="primary"
  />
)

export const LinkButton = props => (
  <ButtonComponent
    {...props}
    color="link"
  />
)

export const InfoButton = props => (
  <ButtonComponent
    {...props}
    color="info"
  />
)

export const WarningButton = props => (
  <ButtonComponent
    {...props}
    color="warning"
  />
)

export const SuccessButton = props => (
  <ButtonComponent
    {...props}
    color="success"
  />
)

export const DefaultButton = props => (
  <ButtonComponent
    {...props}
  />
)

export const DangerButton = props => (
  <ButtonComponent
    {...props}
    color="danger"
  />
)

const FieldComponent = props => (
  <Field>
    <LabelField
      {...props}
    />
    <Control>
      {props.children}
    </Control>
  </Field>
)

const LabelField = ({ label, required, error }) => (
  label ?
    <Label>
      {label}
      {required ? <span className="has-text-danger">（必須）</span> : null}
      {isNotNullOrEmpty(error) ? <ErrorField error={error} /> : null}
    </Label > : null
)

LabelField.propTypes = types.LabelField

const ErrorField = ({ error }) => (
  <span className={`has-text-danger`}>{error}</span>
)

ErrorField.propTypes = {
  error: types.string
}

export const InputText = props => (
  <FieldComponent
    {...props}
  >
    <Input
      {...props}
    />
  </FieldComponent>
)

export const TextField = props => (
  <FieldComponent
    {...props}
  >
    <Textarea
      {...props}
    />
  </FieldComponent>
)

export const RadioField = props => (
  <FieldComponent
    {...props}
  >
    {props.radio.map((obj, i) => (
      <Radio
        {...props}
        key={i}
        value={`${obj.id}`}
        checked={obj.id === props.value}
      >{obj.name}</Radio>
    ))}
  </FieldComponent>
)

export const CheckboxField = ({ value, ...props }) => (
  <FieldComponent
    {...props}
  >
    {props.radio.map((obj, i) => (
      <Checkbox
        {...props}
        key={i}
        value={`${obj.id}`}
        checked={obj.id === value}
      >{obj.name}</Checkbox>
    ))}
  </FieldComponent>
)

CheckboxField.propTypes = {
  value: types.number.isRequired
}

export const DatePicker = props => (
  <FieldComponent
    {...props}
  >
    <Input
      type="date"
      {...props}
    />
  </FieldComponent>
)