import { useState, useEffect } from "react"
import firebase from "firebase/app"
import "firebase/auth"
import { isNotNullOrEmpty, isNullOrEmpty, request } from "utility"
import { API_URI_ENDPOINT, HOSTING_DOMAIN, LANG, SESSION_KEY_NAME } from "constructs"

export const checkHostingDomain = email => {
  const reg = new RegExp(`.*?@${HOSTING_DOMAIN}$`)
  return email.match(reg)
}

export const getEmail = async () => {
  const currentUser = await getUser()
  return currentUser.email
}

export const getUser = async () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => resolve(user))
  })
}

export const getLoginSession = () => {
  return sessionStorage.getItem(SESSION_KEY_NAME)
}

export const setLoginSession = async (user) => {
  if (isNullOrEmpty(user)) return null
  if (checkHostingDomain(user.email)) {
    const token = await user.getIdToken(true)
    sessionStorage.setItem(SESSION_KEY_NAME, token)
    return token
  } else {
    return null
  }
}

export const refreshToken = async () => {
  const currentUser = await getUser()
  return await setLoginSession(currentUser)
}

export const removeLoingSession = () => {
  sessionStorage.removeItem(SESSION_KEY_NAME)
}

export const googleLogin = async setUser => {
  const auth = firebase.auth()
  auth.languageCode = LANG
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.setCustomParameters({
    hd: HOSTING_DOMAIN,
    prompt: "select_account"
  })
  try {
    await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    await auth.signInWithPopup(provider)
    const currentUser = auth.currentUser
    const sessionToken = await setLoginSession(currentUser)
    setUser(sessionToken)
  } catch (e) {
    throw new Error(e)
  }
}

export const googleLogout = () => {
  console.log("logout")
  firebase.auth().signOut().then(() => {
    removeLoingSession()
  }).catch(e => {
    console.error(e)
  })
}

export const useAdministrator = flag => {
  const [useAdmin, setAdmin] = useState(flag)

  const asynchronous = async () => {
    const user = await getUser() || null
    if (isNotNullOrEmpty(user)) {
      const body = JSON.stringify({ data: { email: user.email } })
      const json = await request.post({
        uri: `${API_URI_ENDPOINT.admin.users}/find`,
        body
      })
      setAdmin(!!Object.keys(json).length)
    }
  }

  useEffect(() => {
    asynchronous()
  }, [])

  return useAdmin
}