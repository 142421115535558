import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Columns } from "react-bulma-components"
import Form from "Components/Admin/News/Form"

import { PrimaryButton } from "Components/General/Form"
import { isNullOrEmpty, setDate } from "utility"
import { ADD_EXPIRED_DATE, URI_LIST } from "constructs"
import Validation from "Validation"

const valueState = {
  title: "",
  body: "",
  url: "",
  expired_at: setDate(ADD_EXPIRED_DATE),
}

const errorState = {
  title: "",
  body: "",
  url: "",
  expired_at: ""
}

const New = ({ title, history }) => {
  const [useValues, setValues] = useState(history.location.state ?? valueState)
  const [useErrors, setErrors] = useState(errorState)

  const handleOnChange = e => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...useValues, [name]: value })
    setErrors({ ...useErrors, [name]: Validation.validate(name, value) })
  }

  const handleOnSubmit = async e => {
    e.preventDefault()
    history.push({
      pathname: `${URI_LIST.admin.news.confirm}`,
      state: useValues
    })
  }
  const isSubmit = () => {
    const validErrors = Object.values(Object.keys(useErrors).reduce((obj, key) => {
      obj[key] = Validation.validate(key, useValues[key])
      return obj
    }, {})).every(v => isNullOrEmpty(v))
    return validErrors
  }

  return (
    <>
      <h1 className="title">{title}</h1>
      <p>ポータル画面に一定期間掲載したいお知らせを作成します。<br />期間終了後はポータル画面から取り下げられます。</p>
      <Form
        useValues={useValues}
        useErrors={useErrors}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
      >
        <Columns.Column size={1}>
          <PrimaryButton
            disabled={!isSubmit()}
            onClick={handleOnSubmit}
          >
            確認
          </PrimaryButton>
        </Columns.Column>
      </Form>
    </>
  )
}

New.propTypes = types.NewsNew

export default withRouter(New)