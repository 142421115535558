import React from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import { Section } from "react-bulma-components"

import Index from "Components/Admin/User/Index"
import Add from "Components/Admin/User/Add"

import { URI_LIST } from "constructs"

const User = () => {
  return (
    <Section>
      <Switch>
        <Route exact path={URI_LIST.admin.user.index}><Index title="管理者ユーザ一覧" /></Route>
        <Route exact path={URI_LIST.admin.user.add}><Add title="管理者ユーザ追加" /></Route>
      </Switch>
    </Section>
  )
}

export default withRouter(User)