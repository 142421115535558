import React from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Menu, Button } from "react-bulma-components"
import { DefaultButton } from "Components/General/Form"
import { URI_LIST } from "constructs"

import "./navigation.scss"

const navigationMenu = [
  {
    title: "お知らせ管理",
    items: [
      {
        title: "一覧",
        path: URI_LIST.admin.news.index,
        match: new RegExp(/^\/admin\/news\/?\d?$/)
      },
      {
        title: "作成",
        path: URI_LIST.admin.news.new,
        match: new RegExp(/^\/admin\/news\/[new|confirm|complete]+$/)
      }
    ]
  },
  {
    title: "リンク管理",
    items: [
      {
        title: "一覧",
        path: URI_LIST.admin.group.index,
        match: new RegExp(/^\/admin\/group$/)
      },
    ]
  },
  {
    title: "管理者ユーザ",
    items: [
      {
        title: "一覧",
        path: URI_LIST.admin.user.index,
        match: new RegExp(/^\/admin\/user$/)
      },
      {
        title: "作成",
        path: URI_LIST.admin.user.add,
        match: new RegExp(/^\/admin\/user(\/.*)+$/)
      },
    ]
  }
]

const Navigation = ({ location }) => (
  <Menu>
    {navigationMenu.map((list, i) => (
      <Menu.List
        key={i}
        title={list.title}
      >
        {list.items.map((item, j) => (
          <Menu.List.Item
            key={j}
            active={location.pathname.match(item.match) ? true : false}
            href={item.path}
          >
            {item.title}
          </Menu.List.Item>
        ))}
      </Menu.List>
    ))}
    <Button.Group position="centered" className="to_dashboard">
      <DefaultButton to={URI_LIST.dashboard}>ダッシュボードに戻る</DefaultButton>
    </Button.Group>
  </Menu>
)

Navigation.propTypes = {
  location: types.location
}

export default withRouter(Navigation)