import React, { useEffect, useState } from "react"
import { Switch, Route, BrowserRouter } from "react-router-dom"
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import Auth from "Auth"
import Index from "Components/Index/Index"
import Dashboard from "Components/Dashboard"
import Admin from "Components/Admin"
import { ToastContainer } from "react-toastify"

import { setLoginSession, getLoginSession } from "Functions/authorize"
import config from "config"

import 'react-toastify/dist/ReactToastify.css';

try {
  firebase.initializeApp(config)
} catch (e) {
  console.error(e)
}

const App = () => {
  const [useUser, setUser] = useState(getLoginSession())
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      const token = await setLoginSession(user)
      setUser(token)
    })
  }, [useUser])

  return (
    <BrowserRouter>
      <ToastContainer hideProgressBar={true} />
      <Switch>
        <Route exact path="/">
          <Index setUser={setUser} useUser={useUser} />
        </Route>
        <Auth useUser={useUser}>
          <Route path="/dashboard(/:article)?"><Dashboard /></Route>
          <Route path="/admin(/:article)?"><Admin /></Route>
        </Auth>
      </Switch>
    </BrowserRouter>
  )
}

export default App