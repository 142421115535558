import { useState, useEffect } from "react"
import { request, isNullOrEmpty } from "utility"
import { URI_LIST, API_URI_ENDPOINT } from "constructs"

const getLength = async (setHandle, setSize) => {
  const json = await request.get({
    uri: `${API_URI_ENDPOINT.admin.news}/size`
  })
  if (isNullOrEmpty(json)) setHandle(true)
  else setSize(json)
}

const getNews = async (setHandle, setNews, usePage) => {
  const json = await request.get({
    uri: `${API_URI_ENDPOINT.admin.news}?page=${usePage}`
  })
  if (json != null) {
    setNews(json)
  } else setHandle(true)
}

const useNewsIndex = (history, match) => {
  const [useNews, setNews] = useState([])
  const [usePage, setPage] = useState(+match.params.page || 1)
  const [useSize, setSize] = useState({ size: 0, delta: 0 })
  const [loading, setLoading] = useState(true)
  const [useHandle, setHandle] = useState(false)

  useEffect(() => {
    setLoading(true)
    const f = async () => {
      await getLength(setHandle, setSize)
      await getNews(setHandle, setNews, usePage)
      setLoading(false)
    }
    f()
  }, [usePage])

  const handleChangePage = page => {
    setPage(page)
    setNews([])
    history.push(`${URI_LIST.admin.news.index}/${page}`)
  }

  return [useNews, usePage, useSize, loading, useHandle, handleChangePage]
}

export default useNewsIndex