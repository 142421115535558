import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { Message, Columns } from "react-bulma-components"
import { Input, Radio, SuccessButton, DefaultButton } from "Components/General/Form"
import { LinkRender } from "Components/General/Groups"
import Loading from "Components/General/Loading"
import ErrorHandling from "Components/General/ErrorHandle"
import { toast } from "react-toastify"
import { isNullOrEmpty, request } from "utility"
import { API_URI_ENDPOINT } from "constructs"

import "./group.scss"

const colors = [null, "dark", "primary", "link", "info", "success", "warning", "danger"]

const defaultValues = {
  name: "",
  color: null
}

const ColorSelects = ({ useColor, handleOnChange }) => (
  <div className="control">
    {colors.map((color, i) => (
      <Radio
        key={i}
        data-index={i}
        onChange={handleOnChange}
        name="color"
        checked={useColor === color}
        value={isNullOrEmpty(color) ? "" : color}
        className={`color_select has-background-${isNullOrEmpty(color) ? "grey" : color} ${useColor === color ? "is-selected" : ""}`}
      >{color}</Radio>
    ))}
  </div>
)

ColorSelects.propTypes = types.ColorSelects

const InputMode = ({ useGroup, handleOnChange, handleReset, handleOnSubmit, handleOnUpdate, mode }) => (
  <>
    <Input
      className="short_input"
      size="small"
      value={useGroup.name}
      name="name"
      onChange={handleOnChange}
    />
    <ColorSelects
      useColor={useGroup.color}
      handleOnChange={handleOnChange}
    />
    {mode === "view" ?
      <SuccessButton
        size="small"
        disabled={isNullOrEmpty(useGroup.name)}
        onClick={handleOnSubmit}
      >作成</SuccessButton>
      : <SuccessButton
        size="small"
        disabled={isNullOrEmpty(useGroup.name)}
        onClick={handleOnUpdate}
      >編集</SuccessButton>
    }
    <DefaultButton
      size="small"
      onClick={handleReset}>キャンセル</DefaultButton>
  </>
)

InputMode.propTypes = types.InputMode

const NewGroup = ({ useNewGroupView, setNewGroupView, handleSetNewGroup, handleLinkDelete, handleUpdateGroup, usedGroup, mode = "view" }) => {
  const [useGroup, setGroup] = useState(isNullOrEmpty(usedGroup) ? defaultValues : usedGroup)
  const [loading, setLoading] = useState(false)
  const [useHandle, setHandle] = useState(false)

  const handleOnChange = e => {
    const name = e.target.name
    const value = isNullOrEmpty(e.target.value) ? null : e.target.value
    setGroup({ ...useGroup, [name]: value })
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    const data = { ...useGroup }
    const body = JSON.stringify({ data })
    const json = await request.post({
      uri: API_URI_ENDPOINT.admin.groups,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    else {
      toast.success("グループを作成しました。")
      handleReset()
      handleSetNewGroup(json)
    }
  }

  const handleOnUpdate = async () => {
    const id = useGroup.id
    const data = {
      name: useGroup.name,
      color: useGroup.color,
    }
    const body = JSON.stringify({ data })
    const json = await request.put({
      uri: `${API_URI_ENDPOINT.admin.groups}/${id}`,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    else {
      toast.success("グループを編集しました。")
      handleReset(usedGroup)
      handleUpdateGroup(json)
    }
  }

  const handleReset = (usedGroup = defaultValues) => {
    setLoading(false)
    setGroup(usedGroup)
    setNewGroupView(false)
  }

  return (
    <ErrorHandling useHandle={useHandle}>
      {useNewGroupView ?
        <>
          <Message
            color={useGroup.color}
          >
            <Message.Header>
              <InputMode
                useGroup={useGroup}
                handleOnChange={handleOnChange}
                handleReset={handleReset}
                handleOnSubmit={handleOnSubmit}
                handleOnUpdate={handleOnUpdate}
                mode={mode}
              />
            </Message.Header>
            <Message.Body>
              <Columns breakpoint="desktop">
                {useGroup.links && useGroup.links.length ? <LinkRender
                  groupId={useGroup.id}
                  links={useGroup.links}
                  handleLinkDelete={handleLinkDelete}
                  isAdmin={true}
                /> : null}
              </Columns>
            </Message.Body>
          </Message>
          <Loading
            loading={loading}
          />
        </>
        : null}
    </ErrorHandling>
  )
}

NewGroup.propTypes = types.NewGroup
NewGroup.defaultProps = {
  usedGroup: {
    id: "",
    name: "",
    color: null,
    active: true,
    created_at: {
      _seconds: 0,
      _nanoseconds: 0
    },
    updated_at: {
      _seconds: 0,
      _nanoseconds: 0
    },
    links: []
  }
}

export default NewGroup