import React from "react"
import propTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { useAdministrator } from "Functions/authorize"

const Auth = ({ children }) => {
  const useAdmin = useAdministrator(true)
  return useAdmin ? children : <Redirect to="/" />
}

Auth.propTypes = {
  children: propTypes.node.isRequired
}

export default Auth