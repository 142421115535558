import propTypes from "prop-types"

export const string = propTypes.string
export const number = propTypes.number
export const bool = propTypes.bool
export const func = propTypes.func
export const node = propTypes.node

export const created_at = propTypes.shape({
  _seconds: number.isRequired,
  _nanoseconds: number.isRequired,
})

export const updated_at = created_at

export const expired_at = created_at

export const id = propTypes.oneOfType([number.isRequired, string.isRequired]).isRequired

const title = string.isRequired

export const useUser = propTypes.oneOfType([string, propTypes.oneOf([null])])

export const setUser = func

export const children = node.isRequired

// react-rooter-dom

export const location = propTypes.shape({
  key: string,
  pathname: string,
  search: string,
  hash: string,
  state: propTypes.oneOfType([
    propTypes.object,
    propTypes.array,
    propTypes.string,
    propTypes.number,
    propTypes.bool,
    propTypes.oneOf([null])]),
})

export const history = propTypes.shape({
  action: propTypes.oneOf(['PUSH', 'REPLACE', 'POP']),
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  length: number,
  listen: func,
  location: location,
  push: func,
  replace: func,
})

export const match = propTypes.shape({
  isExact: bool,
  params: propTypes.object,
  path: string.isRequired,
  url: string.isRequired,
})

// error handling

export const ErrorHandling = {
  useHandle: bool.isRequired,
  children: propTypes.oneOfType([node.isRequired, propTypes.oneOf([null])])
}

// form component

export const CustomButton = {
  color: string,
  to: string,
}

export const LabelField = {
  label: string,
  required: propTypes.oneOfType([bool, propTypes.oneOf([null])]),
  error: string
}

// group component

const isAdmin = bool.isRequired

const active = bool.isRequired

export const LinkDeleteButton = {
  handleLinkDelete: func,
  id,
  groupId: id
}

export const GroupDeleteButton = {
  handleGroupDelete: func,
  id
}

export const link = propTypes.shape({
  id,
  ogp: string.isRequired,
  title,
  url: string.isRequired,
  active,
  created_at,
  updated_at,
})

export const LinkRender = {
  links: propTypes.arrayOf(link),
  groupId: id,
  handleLinkDelete: LinkDeleteButton.handleLinkDelete,
  isAdmin
}

export const LinkCard = {
  id,
  view: bool.isRequired,
  handleSetNewLink: func
}

const group = propTypes.shape({
  id,
  name: string.isRequired,
  color: propTypes.oneOfType([string, propTypes.oneOf([null])]),
  active,
  links: propTypes.arrayOf(link),
  created_at,
  updated_at
})

const groupViewTypes = {
  i: number,
  group,
  className: string,
  handleGroupDelete: func,
  handleLinkDelete: func,
  handleSetNewLink: func,
  isAdmin
}

export const ViewGroup = {
  ...groupViewTypes,
  handleEnableEdit: func,
}

export const GroupsRender = {
  ...groupViewTypes,
  handleSetNewLink: func,
}


// news component

const news = propTypes.shape({
  title,
  url: string,
  body: string,
  active,
  created_at,
  updated_at,
  expired_at
})

export const useNews = propTypes.arrayOf(propTypes.shape({
  id,
  data: news
})).isRequired

export const NewsContent = {
  useModal: bool.isRequired,
  useContent: news,
  setModal: func.isRequired
}

// user component

export const UsersList = {
  useUsers: propTypes.arrayOf(propTypes.shape({
    id,
    data: propTypes.shape({
      email: string.isRequired,
      active,
      created_at,
      updated_at
    })
  })),
  handleUserDelete: func.isRequired
}

// news component

export const NewsIndex = {
  title,
  history,
  match
}

export const newsObject = propTypes.shape({
  title,
  body: string.isRequired,
  url: string,
  expired_at: string.isRequired
})

export const NewsForm = {
  children,
  useValues: newsObject.isRequired,
  useErrors: newsObject.isRequired,
  handleOnChange: func.isRequired,
  handleOnSubmit: func.isRequired,
}

export const NewsConfirm = {
  title,
  loading: bool.isRequired,
  confirmValue: newsObject.isRequired,
  handleReturn: func.isRequired,
  handleOnSubmit: func.isRequired,
}

export const NewsNew = {
  title,
  history
}

export const NewsNewConfirm = NewsNew

export const NewsEdit = {
  title,
  history,
  match
}

export const NewsEditConfrm = NewsNewConfirm

// group component

export const NewGroup = {
  useNewGroupView: bool,
  setNewGroupView: func,
  handleSetNewGroup: func,
  handleLinkDelete: func,
  handleUpdateGroup: func,
  usedGroup: propTypes.object,
  mode: string
}

export const InputMode = {
  useGroup: propTypes.object,
  handleOnChange: func,
  handleReset: func,
  handleOnSubmit: func,
  handleOnUpdate: func,
  mode: string
}

export const ColorSelects = {
  useColor: propTypes.oneOf([null, "dark", "primary", "link", "info", "success", "warning", "danger"]),
  handleOnChange: func
}