import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import ConfirmForm from "Components/Admin/News/Confirm"
import ErrorHandling from "Components/General/ErrorHandle"
import { toast } from "react-toastify"

import { request, isNullOrEmpty } from "utility"
import { URI_LIST, API_URI_ENDPOINT } from "constructs"

const Confirm = ({ title, history }) => {
  const id = history.location.state.id
  const confirmValue = { ...history.location.state.data }
  const [loading, setLoading] = useState(false)
  const [useHandle, setHandle] = useState(false)

  const handleReturn = () => {
    history.push({
      pathname: `${URI_LIST.admin.news.index}/${id}/edit`,
      state: { data: confirmValue }
    })
    // history.goBack()
  }

  const handleOnSubmit = async e => {
    setLoading(true)
    const data = {
      title: confirmValue.title,
      body: confirmValue.body,
      url: confirmValue.url,
      expired_at: confirmValue.expired_at,
    }
    const body = JSON.stringify({ data })
    const json = await request.put({
      uri: `${API_URI_ENDPOINT.admin.news}/${id}`,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    else {
      setLoading(false)
      toast.success("お知らせを編集しました。")
      history.push(URI_LIST.admin.news.index)
    }
  }

  return (
    <ErrorHandling useHandle={useHandle}>
      <ConfirmForm
        title={title}
        loading={loading}
        confirmValue={confirmValue}
        handleOnSubmit={handleOnSubmit}
        handleReturn={handleReturn}
      />
    </ErrorHandling>
  )
}

Confirm.propTypes = types.NewsEditConfrm

export default withRouter(Confirm)