import React from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import { Section } from "react-bulma-components"

import Index from "./News/Index"
import New from "./News/New/New"
import Confirm from "./News/New/Confirm"
import Edit from "./News/Edit/Edit"
import EditConfirm from "./News/Edit/Confirm"

import { URI_LIST } from "constructs"

const titles = {
  index: "お知らせ一覧",
  new: "お知らせ新規作成",
  edit: "お知らせ編集",
}

const News = () => (
  <Section>
    <Switch>
      <Route exact path={URI_LIST.admin.news.new}><New title={titles.new} /></Route>
      <Route exact path={URI_LIST.admin.news.confirm}><Confirm title={titles.new} /></Route>
      <Route exact path={`${URI_LIST.admin.news.index}/:id/edit`}><Edit title={titles.edit} /></Route>
      <Route exact path={`${URI_LIST.admin.news.index}/:id/confirm`}><EditConfirm title={titles.edit} /></Route>
      <Route exact path={`${URI_LIST.admin.news.index}/:page?`}><Index title={titles.index} /></Route>
    </Switch>
  </Section>
)


export default withRouter(News)