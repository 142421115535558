import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Section, Table, Button } from "react-bulma-components"
import { DangerButton } from "Components/General/Form"
import Loading from "Components/General/Loading"
import ErrorHandling from "Components/General/ErrorHandle"

import useUser from "Functions/useUser"

const UsersList = ({ setLoading }) => {
  const [useUsers, useHandle, handleUserDelete] = useUser(setLoading)

  return (
    <ErrorHandling useHandle={useHandle}>
      {useUsers.map((user, i) => (
        <tr key={i}>
          <td>{user.data.email}</td>
          <td>
            <Button.Group position="right">
              <DangerButton
                size="small"
                onClick={() => handleUserDelete(user)}
              >削除</DangerButton>
            </Button.Group>
          </td>
        </tr>
      ))}
    </ErrorHandling>
  )
}

UsersList.propTypes = {
  setLoading: types.func.isRequired
}

const UsersTable = () => {
  const [loading, setLoading] = useState(true)
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>メールアドレス</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <UsersList setLoading={setLoading} />
        </tbody>
      </Table>
      <Loading loading={loading} />
    </>
  )
}

const Index = ({ title }) => (
  <>
    <h1 className="title">{title}</h1>
    <p>管理者とするユーザ一覧です。<br />管理権限を外したい場合は該当のメールアドレスを削除してください。</p>
    <Section>
      <UsersTable />
    </Section>
  </>
)


Index.propTypes = {
  title: types.string.isRequired
}

export default withRouter(Index)