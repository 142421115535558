import { useState } from "react"
import * as types from "Functions/propTypes"
import { toast } from "react-toastify"
import { URI_LIST, API_URI_ENDPOINT } from "constructs"
import { request, isNullOrEmpty } from "utility"
import Validation from "Validation"

const useUserAdd = history => {
  const [useEmail, setEmail] = useState("")
  const [useError, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [useHandle, setHandle] = useState(false)

  const handleOnChange = e => {
    const name = e.target.name
    const value = e.target.value
    setEmail(value)
    setError(Validation.validate(name, value))
  }

  const handleOnSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const data = { email: useEmail }
    const body = JSON.stringify({ data })
    const json = await request.post({
      uri: API_URI_ENDPOINT.admin.users,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    if (json.id) {
      toast.success("ユーザを追加しました。")
      history.push(URI_LIST.admin.user.index)
    }
  }

  const isSubmit = () => isNullOrEmpty(Validation.validate("email", useEmail))

  return [useEmail, useError, loading, useHandle, isSubmit, handleOnChange, handleOnSubmit]
}

useUserAdd.propTypes = {
  history: types
}

export default useUserAdd