import React from "react"
import * as types from "Functions/propTypes"
import { Section, Columns } from "react-bulma-components"
import { NEWS_FORM_STATIC } from "constructs"
import { InputText, TextField, DatePicker } from "Components/General/Form"

const Form = ({ children, useValues, useErrors, handleOnChange, handleOnSubmit }) => (
  <Section>
    <Columns>
      <Columns.Column size={12}>
        <form
          onSubmit={handleOnSubmit}
        >
          <InputText
            {...NEWS_FORM_STATIC.title}
            error={useErrors.title}
            onChange={handleOnChange}
            value={useValues.title}
          />
          <TextField
            {...NEWS_FORM_STATIC.body}
            error={useErrors.body}
            onChange={handleOnChange}
            value={useValues.body}
          />
          <InputText
            {...NEWS_FORM_STATIC.url}
            error={useErrors.url}
            onChange={handleOnChange}
            value={useValues.url}
          />
          <DatePicker
            {...NEWS_FORM_STATIC.expired_at}
            error={useErrors.expired_at}
            onChange={handleOnChange}
            value={useValues.expired_at}
          />
        </form>
      </Columns.Column>
      {children}
    </Columns>
  </Section>
)

Form.propTypes = types.NewsForm

export default Form