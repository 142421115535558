import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { Box, Columns } from "react-bulma-components"
import { InfoButton, InputText, SuccessButton, DefaultButton } from "Components/General/Form"
import ErrorHandling from "Components/General/ErrorHandle"
import { toast } from "react-toastify"
import { isNotNullOrEmpty, request, isNullOrEmpty } from "utility"
import { API_URI_ENDPOINT } from "constructs"

const LinkCard = ({ id, handleSetNewLink }) => {
  const defaultValues = {
    title: "",
    url: "",
  }
  const [useValues, setValues] = useState(defaultValues)
  const [useView, setView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [useHandle, setHandle] = useState(false)

  const handleOnChange = e => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...useValues, [name]: value })
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    const body = JSON.stringify({ data: { ...useValues } })
    const json = await request.post({
      uri: `${API_URI_ENDPOINT.admin.groups}/${id}/links`,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    else linkCreated(id, json)
  }

  const linkCreated = (id, json) => {
    toast.success("リンクを作成しました。")
    setView(false)
    setLoading(false)
    setValues(defaultValues)
    handleSetNewLink(id, json)
  }

  const handleReset = () => {
    setValues(defaultValues)
    setView(false)
  }

  const isSubmit = () => {
    return Object.values(useValues).every(v => isNotNullOrEmpty(v))
  }

  return (
    <ErrorHandling useHandle={useHandle}>
      {useView ?
        <Box>
          <InputText
            placeholder="リンク名"
            name="title"
            size="small"
            disabled={loading}
            value={useValues.title}
            onChange={handleOnChange}
          />
          <InputText
            placeholder="URL"
            size="small"
            name="url"
            disabled={loading}
            value={useValues.url}
            onChange={handleOnChange}
          />
          <Columns>
            <Columns.Column>
              <SuccessButton
                size="small"
                loading={loading}
                disabled={!isSubmit()}
                onClick={handleOnSubmit}
              >リンク作成</SuccessButton>
            </Columns.Column>
            <Columns.Column>
              <DefaultButton
                size="small"
                loading={loading}
                onClick={handleReset}
              >キャンセル</DefaultButton>
            </Columns.Column>
          </Columns>
        </Box>
        : <InfoButton
          onClick={() => setView(true)}
        >新しいリンクを作成</InfoButton>}
    </ErrorHandling>
  )
}

LinkCard.propTypes = {
  id: types.id,
  handleSetNewLink: types.func
}

export default LinkCard