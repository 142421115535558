import React from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Section, Table, Pagination } from "react-bulma-components"
import Loading from "Components/General/Loading"
import ErrorHandling from "Components/General/ErrorHandle"
import { dateFormat } from "utility"
import { URI_LIST } from "constructs"
import useNewsIndex from "Functions/useNewsIndex"
import "../admin.scss"

const NewsTable = ({ useNews }) => {
  const today = new Date()
  return useNews.map((news, i) => {
    const expired = new Date(news.data.expired_at._seconds * 1000)
    const openStatus = today < expired
    return (
      <tr key={i} className={openStatus ? null : "closed"}>
        <td>{openStatus ? "掲載中" : "掲載終了"}</td>
        <td><a href={`${URI_LIST.admin.news.index}/${news.id}/edit`}>
          {news.data.title}
        </a></td>
        <td>{dateFormat(expired)}</td>
      </tr>
    )
  })
}

NewsTable.propTypes = types.useNews

const Index = ({ title, history, match }) => {
  const [useNews, usePage, useSize, loading, useHandle, handleChangePage] = useNewsIndex(history, match)
  return (
    <ErrorHandling useHandle={useHandle}>
      <h1 className="title">{title}</h1>
      <Section>
        {loading ? <Loading loading={loading} /> :
          <Table
            striped={false}
            className="news">
            <thead>
              <tr className="news_head">
                <th className="status">ステータス</th>
                <th>表題</th>
                <th className="expired">掲載期間</th>
              </tr>
            </thead>
            <tbody>
              <NewsTable
                useNews={useNews}
              />
            </tbody>
          </Table>
        }
        <Pagination
          current={usePage}
          total={useSize.delta}
          delta={3}
          onChange={handleChangePage}
          next="次へ"
          previous="前へ"
          className="is-centered" />
      </Section>
    </ErrorHandling>
  )
}

Index.propTypes = types.NewsIndex

export default withRouter(Index)