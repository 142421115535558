import React from "react"
import * as types from "Functions/propTypes"
import Loading from "Components/General/Loading"
import GroupsRender from "Components/General/Groups"
import NewGroups from "./NewGroup"
import ErrorHandling from "Components/General/ErrorHandle"

import useGroups from "Functions/useGroup"

const Groups = ({ useNewGroupView, setNewGroupView }) => {
  const [useGroup, useHandle, handleGroupDelete, handleLinkDelete, handleSetNewGroup, handleSetNewLink, handleUpdateGroup] = useGroups()

  return (
    <ErrorHandling useHandle={useHandle}>
      <NewGroups
        useNewGroupView={useNewGroupView}
        setNewGroupView={setNewGroupView}
        handleSetNewGroup={handleSetNewGroup}
      />
      {useGroup.length ?
        useGroup.map((group, i) => (
          <GroupsRender
            key={i}
            group={group}
            isAdmin={true}
            handleGroupDelete={handleGroupDelete}
            handleLinkDelete={handleLinkDelete}
            handleSetNewLink={handleSetNewLink}
            handleUpdateGroup={handleUpdateGroup}
          />
        ))
        : <Loading loading={true} />}
    </ErrorHandling>
  )
}

Groups.propTypes = {
  useNewGroupView: types.bool,
  setNewGroupView: types.func
}

export default Groups