import React, { useEffect, useState } from "react"
import * as types from "Functions/propTypes"
import { getEmail } from "Functions/authorize"

import "./googleCalender.scss"

const calenderUrl = "https://calendar.google.com/calendar/embed?"

const defaultParams = {
  height: 600,
  wkst: 1,
  bgcolor: "%23ffffff",
  ctz: "Asia%2FTokyo",
  showTitle: 0,
  showNav: 1,
  showDate: 1,
  showPrint: 0,
  showTabs: 1,
  showCalendars: 0,
  showTz: 0,
  color: "%23039BE5"
}

const buildUrl = params => {
  const query = Object.keys(params).map(key => `${key}=${params[key]}`).join("&")
  return `${calenderUrl}${query}`
}

buildUrl.propTypes = {
  height: types.number.isRequired,
  wkst: types.number.isRequired,
  bgcolor: types.string.isRequired,
  ctz: types.string.isRequired,
  showTitle: types.number.isRequired,
  showNav: types.number.isRequired,
  showDate: types.number.isRequired,
  showPrint: types.number.isRequired,
  showTabs: types.number.isRequired,
  showCalendars: types.number.isRequired,
  showTz: types.number.isRequired,
  color: types.string.isRequired,
  src: types.string.isRequired,
}

const GoogleCalender = () => {
  const [useEmail, setEmail] = useState("")
  useEffect(() => {
    const f = async () => {
      const email = await getEmail()
      setEmail(email)
    }
    f()
  })
  const params = { ...defaultParams, src: useEmail }
  const url = buildUrl(params)
  return (
    <div className="calender">
      <iframe src={url} frameBorder="0" title="googleCalender" scrolling="no"></iframe>
    </div>)
}

export default GoogleCalender