import React, { useEffect, useState } from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { Columns } from "react-bulma-components"
import Form from "Components/Admin/News/Form"
import ErrorHandling from "Components/General/ErrorHandle"
import { toast } from "react-toastify"
import { PrimaryButton, DangerButton } from "Components/General/Form"
import { isNullOrEmpty, request, dateFormat } from "utility"
import { URI_LIST, API_URI_ENDPOINT } from "constructs"
import Validation from "Validation"

const dateNormalization = data => {
  return {
    ...data,
    expired_at: typeof data.expired_at === "string" ? data.expired_at : dateFormat(new Date(data.expired_at._seconds * 1000))
  }
}

dateNormalization.propTypes = types.newsObject

const setInitializeErrors = values => {
  return Object.keys(values).reduce((obj, key) => {
    obj[key] = Validation.validate(key, values[key])
    return obj
  }, {})
}

setInitializeErrors.propTypes = types.newsObject

const defaultState = {
  title: "",
  body: "",
  url: "",
  expired_at: ""
}

const Edit = ({ match, history, title }) => {
  const [useErrors, setErrors] = useState(defaultState)
  const [useValues, setValues] = useState(defaultState)
  const [useHandle, setHandle] = useState(false)

  const id = match.params.id
  const state = isNullOrEmpty(history.location.state) ? null : history.location.state.data

  useEffect(() => {
    const f = async () => {
      if (isNullOrEmpty(state)) {
        const json = await request.get({
          uri: `${API_URI_ENDPOINT.admin.news}/${id}`
        })
        const data = dateNormalization(json.data)
        setValues(data)
        setErrors(setInitializeErrors(data))
      } else {
        setValues(state)
      }
    }
    f()
  }, [id, state])

  const handleOnChange = e => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...useValues, [name]: value })
    setErrors({ ...useErrors, [name]: Validation.validate(name, value) })
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    history.push({
      pathname: `${URI_LIST.admin.news.index}/${id}/confirm`,
      state: {
        id,
        data: { ...useValues }
      }
    })
  }

  const handleOnDelete = async e => {
    if (window.confirm("お知らせを削除しますがよろしいですか？")) {
      const json = await request.delete({
        uri: `${API_URI_ENDPOINT.admin.news}/${id}`,
      })
      if (isNullOrEmpty) setHandle(true)
      if (json.id) {
        toast.success("お知らせを削除しました。")
        history.push(URI_LIST.admin.news.index)
      }
    }
  }

  const isSubmit = () => {
    const validErrors = Object.values(Object.keys(useErrors).reduce((obj, key) => {
      obj[key] = Validation.validate(key, useValues[key])
      return obj
    }, {})).every(v => isNullOrEmpty(v))
    return validErrors
  }

  return (
    <ErrorHandling useHandle={useHandle}>
      <h1 className="title">{title}</h1>
      <p>登録済みのお知らせ内容を変更します。<br />削除する場合は削除ボタンを押してください。</p>
      <Form
        useValues={useValues}
        useErrors={useErrors}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
      >
        <Columns.Column size={1}>
          <DangerButton onClick={handleOnDelete}>削除</DangerButton>
        </Columns.Column>
        <Columns.Column size={1}>
          <PrimaryButton disabled={!isSubmit()} onClick={handleOnSubmit}>確認</PrimaryButton>
        </Columns.Column>
      </Form>
    </ErrorHandling>
  )
}

Edit.propTypes = types.NewsEdit

export default withRouter(Edit)