import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import ConfirmForm from "Components/Admin/News/Confirm"
import ErrorHandling from "Components/General/ErrorHandle"

import { request, isNullOrEmpty } from "utility"
import { URI_LIST, API_URI_ENDPOINT } from "constructs"

const Confirm = ({ title, history }) => {
  const confirmValue = { ...history.location.state }

  const [loading, setLoading] = useState(false)
  const [useHandle, setHandle] = useState(false)

  const handleReturn = () => {
    history.push({
      pathname: URI_LIST.admin.news.new,
      state: confirmValue
    })
  }

  const handleOnSubmit = async e => {
    setLoading(true)
    const body = JSON.stringify({ data: confirmValue })
    const json = await request.post({
      uri: API_URI_ENDPOINT.admin.news,
      body
    })
    if (isNullOrEmpty(json)) setHandle(true)
    else {
      setLoading(false)
      toast.success("お知らせを作成しました。")
      history.push(URI_LIST.admin.news.index)
    }
  }

  return (
    <ErrorHandling useHandle={useHandle}>
      <ConfirmForm
        title={title}
        loading={loading}
        confirmValue={confirmValue}
        handleOnSubmit={handleOnSubmit}
        handleReturn={handleReturn}
      />
    </ErrorHandling>
  )
}

Confirm.propTypes = types.NewsNewConfirm


export default withRouter(Confirm)