export const HOSTING_DOMAIN = process.env.REACT_APP_HOSTING_DOMAIN

export const API_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT : process.env.REACT_APP_PRODUCTION_API_ENDPOINT

// APIでは401と500以外を定義していない
export const STATUS_CODE = {
  ok: 200,
  unauthorized: 401,
  internalServerError: 500
}

export const IMAGE_FALLBACK = "https://firebasestorage.googleapis.com/v0/b/organized-portal.appspot.com/o/m_e_others_481.png?alt=media"

export const LANG = "ja"

export const SESSION_KEY_NAME = "user"

export const ADD_EXPIRED_DATE = 7

const URI_ADMIN = "/admin"
const URI_NEWS = "/news"
const URI_GROUP = "/group"
const URI_USER = "/user"

export const URI_LIST = {
  index: "/",
  dashboard: "/dashboard",
  admin: {
    index: URI_ADMIN,
    news: {
      index: `${URI_ADMIN}${URI_NEWS}`,
      new: `${URI_ADMIN}${URI_NEWS}/new`,
      confirm: `${URI_ADMIN}${URI_NEWS}/confirm`,
      complete: `${URI_ADMIN}${URI_NEWS}/complete`,
    },
    group: {
      index: `${URI_ADMIN}${URI_GROUP}`
    },
    user: {
      index: `${URI_ADMIN}${URI_USER}`,
      add: `${URI_ADMIN}${URI_USER}/add`,
      complete: `${URI_ADMIN}${URI_USER}/complete`,
    }
  }
}

export const API_URI_ENDPOINT = {
  admin: {
    news: `${URI_ADMIN}${URI_NEWS}`,
    groups: `${URI_ADMIN}${URI_GROUP}s`,
    users: `${URI_ADMIN}${URI_USER}s`,
  },
  news: `${URI_NEWS}`,
  users: `${URI_USER}s`,
  groups: `${URI_GROUP}s`,
}

export const NEWS_FORM_STATIC = {
  title: {
    label: "表題",
    name: "title",
    required: true,
  },
  body: {
    label: "本文",
    name: "body",
    required: true,
  },
  url: {
    label: "URL",
    name: "url",
    required: false,
  },
  expired_at: {
    label: "掲載期間",
    name: "expired_at",
    required: true,
  },
}