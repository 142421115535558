import React from "react"
import * as types from "Functions/propTypes"
import { Section, Columns } from "react-bulma-components"

import { InputText, TextField, PrimaryButton, DefaultButton } from "Components/General/Form"
import { NEWS_FORM_STATIC } from "constructs"

const Confirm = ({ title, loading, confirmValue, handleReturn, handleOnSubmit }) => (
  <>
    <h1 className="title">{title}</h1>
    <p>内容を確認の上、登録してください。<br />変更する場合は戻って変更してください。</p>
    <Section>
      <Columns>
        <Columns.Column size={12}>
          <InputText
            {...NEWS_FORM_STATIC.title}
            disabled={true}
            value={confirmValue.title}
          />
          <TextField
            {...NEWS_FORM_STATIC.body}
            disabled={true}
            value={confirmValue.body}
          />
          <InputText
            {...NEWS_FORM_STATIC.url}
            disabled={true}
            value={confirmValue.url}
          />
          <InputText
            {...NEWS_FORM_STATIC.expired_at}
            disabled={true}
            value={confirmValue.expired_at}
          />
        </Columns.Column>
        <Columns.Column size={1}>
          <DefaultButton
            onClick={handleReturn}
            loading={loading}
          >戻る</DefaultButton>
        </Columns.Column>
        <Columns.Column size={1}>
          <PrimaryButton
            onClick={handleOnSubmit}
            loading={loading}
          >登録</PrimaryButton>
        </Columns.Column>
      </Columns>
    </Section>
  </>
)

Confirm.propTypes = types.NewsConfirm

export default Confirm