import { API_URI, STATUS_CODE } from "constructs"
import { getLoginSession, refreshToken, googleLogout } from "Functions/authorize"
import { toast } from "react-toastify"

// null、undefined、0、"0"、""、空の配列、空のオブジェクトの判定
export const isNullOrEmpty = arg => {
  if (arg == null) return true
  if (arg === 0 || arg === "0") return true
  if (Array.isArray(arg)) return !arg.length
  if (toString.call(arg).slice(8, -1).toLowerCase() === "object") return isNullOrEmpty(Object.keys(arg))
  return !arg
}

export const isNotNullOrEmpty = arg => !isNullOrEmpty(arg)

export const dateFormat = date => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
}

export const setDate = (addDate = 0) => {
  const today = new Date()
  today.setDate(today.getDate() + addDate)
  return dateFormat(today)
}

const notify = message => {
  toast.error(message)
  // googleLogout()
}

// 認証がタイムアウトした際、リトライする回数


class Request {
  get mode() {
    return "cors"
  }

  get requestRetryCount() {
    return 3
  }

  async headers(retry) {
    const token = retry < this.requestRetryCount ? await refreshToken() : getLoginSession()
    return {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  }
  async api({ method, uri, body, retry }) {
    const headers = await this.headers(retry)
    switch (method) {
      case "get":
        return await fetch(`${API_URI}${uri}`, {
          headers,
          mode: this.mode
        })
      case "post":
      case "put":
        return await fetch(`${API_URI}${uri}`, {
          method,
          headers,
          mode: this.mode,
          body
        })
      case "delete":
        return await fetch(`${API_URI}${uri}`, {
          method,
          headers,
          mode: this.mode
        })
      default:
        return null
    }
  }
  async handle({ uri, body, method, retry }) {
    try {
      if (!retry) {
        const error = new Error("認証されませんでした。再度ログインしてください。")
        error.statusCode = STATUS_CODE.unauthorized
        throw error
      }
      const response = await this.api({ uri, body, method, retry })
      if (response.status === STATUS_CODE.internalServerError) {
        const error = new Error("サーバでエラーが発生しました。再度操作してください。")
        error.statusCode = STATUS_CODE.internalServerError
        throw error
      }
      if (response.status === STATUS_CODE.unauthorized) {
        retry = retry - 1
        return this.handle({ uri, body, method, retry })
      }
      if (response.status === STATUS_CODE.ok) {
        return await response.json()
      }
    } catch (e) {
      notify(e.message)
      return null
    }
  }
  async get({ uri }) {
    return await this.handle({
      uri,
      method: "get",
      retry: this.requestRetryCount
    })
  }
  async post({ uri, body }) {
    return await this.handle({
      uri,
      body,
      method: "post",
      retry: this.requestRetryCount
    })
  }
  async put({ uri, body }) {
    return await this.handle({
      uri,
      body,
      method: "put",
      retry: this.requestRetryCount
    })
  }
  async delete({ uri }) {
    return await this.handle({
      uri,
      method: "delete",
      retry: this.requestRetryCount
    })
  }
}

export const request = new Request()
