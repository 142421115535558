import React, { useState } from "react"
import * as types from "Functions/propTypes"
import { Button, Columns, Message, Box, Media, Image } from "react-bulma-components"
import Link from "Components/Admin/Group/Link"
import GroupEdit from "Components/Admin/Group/NewGroup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isNotNullOrEmpty } from "utility"
import { IMAGE_FALLBACK } from "constructs"

const LinkDeleteButton = ({ handleLinkDelete, id, groupId }) => (
  isNotNullOrEmpty(handleLinkDelete) ? < Media.Item position="right" >
    <Button
      size="small"
      remove
      onClick={() => handleLinkDelete(groupId, id)}
    />
  </Media.Item > : null
)

LinkDeleteButton.propTypes = types.LinkDeleteButton

const LinkOgp = ({ link, view }) => (
  view ?
    <Media.Item renderAs="figure" position="left">
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        <Image size={64} src={link.ogp} fallback={IMAGE_FALLBACK} />
      </a>
    </Media.Item>
    : null
)

LinkOgp.propTypes = {
  link: types.link
}

export const LinkRender = ({ groupId, links, handleLinkDelete, isAdmin }) => (
  links.map((link, j) => (
    <Columns.Column size={isAdmin ? 4 : 12} key={j}>
      <Box>
        <Media>
          <LinkOgp
            view={isNotNullOrEmpty(link.ogp)}
            link={link}
          />
          <Media.Item>
            <strong>
              <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
            </strong>
          </Media.Item>
          <LinkDeleteButton
            groupId={groupId}
            id={link.id}
            handleLinkDelete={handleLinkDelete}
          />
        </Media>
      </Box>
    </Columns.Column>
  ))
)

LinkRender.propTypes = types.LinkRender

const GroupDeleteButton = ({ handleGroupDelete, id }) => (
  isNotNullOrEmpty(handleGroupDelete) ?
    <Button
      remove
      onClick={() => handleGroupDelete(id)}
    />
    : null
)

GroupDeleteButton.propTypes = types.GroupDeleteButton

const LinkCard = ({ id, handleSetNewLink, view }) => (
  view ?
    <Columns>
      <Columns.Column size={4}>
        <Link
          id={id}
          handleSetNewLink={handleSetNewLink}
        />
      </Columns.Column>
    </Columns>
    : null
)

LinkCard.propTypes = types.LinkCard

const ViewGroup = ({ i, group, className, handleGroupDelete, handleLinkDelete, handleSetNewLink, handleEnableEdit, isAdmin }) => (
  <Message
    color={group.color}
    className={className}
    key={i}
  >
    <Message.Header>
      <div className="group_header">
        <span className="group_name">{group.name}</span>
        {isAdmin ?
          <FontAwesomeIcon icon="pen" onClick={handleEnableEdit} />
          : null
        }
      </div>
      <GroupDeleteButton
        id={group.id}
        handleGroupDelete={handleGroupDelete}
      ></GroupDeleteButton>
    </Message.Header>
    <Message.Body>
      <Columns breakpoint="desktop">
        <LinkRender
          groupId={group.id}
          links={group.links}
          isAdmin={isAdmin}
          handleLinkDelete={handleLinkDelete}
        />
      </Columns>
      <LinkCard
        id={group.id}
        view={isNotNullOrEmpty(handleGroupDelete)}
        handleSetNewLink={handleSetNewLink}
      />
    </Message.Body>
  </Message>
)

ViewGroup.propTypes = types.ViewGroup

const GroupsRender = ({ i, group, className, handleGroupDelete, handleLinkDelete, handleSetNewLink, handleUpdateGroup, isAdmin }) => {
  const [useEdit, setEdit] = useState(false)

  const handleEnableEdit = () => {
    setEdit(true)
  }

  return useEdit ?
    <GroupEdit
      useNewGroupView={useEdit}
      setNewGroupView={setEdit}
      handleUpdateGroup={handleUpdateGroup}
      handleLinkDelete={handleLinkDelete}
      usedGroup={group}
      mode="edit"
    /> :
    <ViewGroup
      i={i}
      group={group}
      className={className}
      handleGroupDelete={handleGroupDelete}
      handleLinkDelete={handleLinkDelete}
      handleSetNewLink={handleSetNewLink}
      handleEnableEdit={handleEnableEdit}
      isAdmin={isAdmin}
    />
}

GroupsRender.propTypes = types.GroupsRender

export default GroupsRender