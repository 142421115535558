import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Section } from "react-bulma-components"
import { InfoButton } from "Components/General/Form"

import Groups from "./Groups"

const Index = () => {
  const [useNewGroupView, setNewGroupView] = useState(false)

  return (
    <Section>
      <h1 className="title">リンク一覧</h1>
      <InfoButton
        isField={true}
        disabled={useNewGroupView}
        onClick={() => setNewGroupView(true)}
      >グループを作成</InfoButton>
      <Groups
        useNewGroupView={useNewGroupView}
        setNewGroupView={setNewGroupView}
      />
    </Section>
  )
}

export default withRouter(Index)