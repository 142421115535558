import { useState, useEffect } from "react"
import * as types from "Functions/propTypes"
import { toast } from "react-toastify"
import { request } from "utility"
import { API_URI_ENDPOINT } from "constructs"

const useUser = setLoading => {
  const [useUsers, setUsers] = useState([])
  const [useHandle, setHandle] = useState(false)

  useEffect(() => {
    const f = async () => {
      const json = await request.get({
        uri: API_URI_ENDPOINT.admin.users
      })
      if (json == null) setHandle(true)
      else {
        setUsers(json)
        setLoading(false)
      }
    }
    f()
  }, [setLoading])

  const handleUserDelete = async user => {
    const email = user.data.email
    if (window.confirm(`${email}を削除しますがよろしいですか？`)) {
      const id = user.id
      const json = await request.delete({
        uri: `${API_URI_ENDPOINT.admin.users}/${id}`,
      })
      if (json == null) setHandle(true)
      else {
        toast.success("ユーザを削除しました。")
        stateUserRemove(json.id)
      }
    }
  }

  const stateUserRemove = id => {
    const index = useUsers.findIndex(user => user.id === id)
    useUsers.splice(index, 1)
    setUsers([...useUsers])
  }

  return [useUsers, useHandle, handleUserDelete]
}

useUser.propTypes = {
  setLoading: types.func.isRequired
}

export default useUser