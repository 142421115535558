import { isNullOrEmpty } from "./utility"
import { HOSTING_DOMAIN } from "constructs"

const REGS = {
  url: new RegExp(/^https?(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)+?$/i),
  email: new RegExp(/^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i),
  domain: new RegExp(`^[\\w+\\-.]+@${HOSTING_DOMAIN}$`, "i")
}

const newsTitleValidation = value => {
  if (isNullOrEmpty(value)) return "表題を入力してください。"
  return ""
}

const newsBodyValidation = value => {
  if (isNullOrEmpty(value)) return "本文を入力してください。"
  return ""
}

const newsUrlValidation = value => {
  if (isNullOrEmpty(value)) return ""
  if (isNullOrEmpty(value.match(REGS.url))) return "URLが正しくありません。"
  return ""
}

const newsExpiredValidation = value => {
  if (isNullOrEmpty(value)) return "掲載期間を入力してください。"
  if (new Date(value) <= new Date()) return "掲載期間は今日より未来を入力してください。"
  return ""
}

const emailValidation = value => {
  if (isNullOrEmpty(value)) return "メールアドレスを入力してください。"
  if (isNullOrEmpty(value.match(REGS.email))) return "メールアドレスが正しくありません。"
  if (isNullOrEmpty(value.match(REGS.domain))) return "ドメインが正しくありません。"
  return ""
}

class Validation {
  static validate(field, value) {
    switch (true) {
      case /^title$/.test(field):
        return newsTitleValidation(value)
      case /^body$/.test(field):
        return newsBodyValidation(value)
      case /^url$/.test(field):
        return newsUrlValidation(value)
      case /^expired_at$/.test(field):
        return newsExpiredValidation(value)
      case /^email$/.test(field):
        return emailValidation(value)
      default:
        return ""
    }
  }
}

export default Validation