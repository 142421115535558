import React, { useState, useEffect } from "react"
import propTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Hero, Section, Columns } from "react-bulma-components"
import GroupRender from "Components/General/Groups"
import Loading from "Components/General/Loading"
import Header from "Components/Dashboard/Header"
import News from "Components/Dashboard/Index/News"
import GoogleSearch from "Components/Dashboard/Index/GoogleSearch"
import GoogleCalender from "Components/Dashboard/Index/GoogleCalender"

import { request, isNullOrEmpty } from "utility"
import { API_URI_ENDPOINT } from "constructs"

import useGroups from "Functions/useGroup"

import "./dashboard.scss"

const getNews = async setNews => {
  const json = await request.get({
    uri: API_URI_ENDPOINT.news,
  })
  setNews(json)
}

getNews.propTypes = {
  setNews: propTypes.func.isRequired
}

const Groups = () => {
  const [useLoading, setLoading] = useState(true)
  const [useGroup] = useGroups()

  useEffect(() => {
    setLoading(isNullOrEmpty(useGroup))
  }, [useGroup])

  return (
    <Columns>
      <Loading loading={useLoading} />
      {useGroup.map((group, i) => (
        <Columns.Column size={3} key={i}>
          <GroupRender
            group={group}
            className="custom_messages"
            isAdmin={false}
          />
        </Columns.Column>
      ))}
    </Columns>
  )
}

const Index = () => {
  const [useNews, setNews] = useState([])

  useEffect(() => {
    getNews(setNews)
  }, [])

  return (
    <>
      <Header />
      <Hero color="info">
        <GoogleSearch />
        <News useNews={useNews} />
        <Section>
          <Groups />
          <GoogleCalender />
        </Section>
      </Hero>
    </>
  )
}

export default withRouter(Index)