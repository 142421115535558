import React from "react"
import * as types from "Functions/propTypes"
import { Redirect, withRouter } from "react-router-dom"
import { Hero, Container, Heading, Button } from "react-bulma-components"

import { googleLogin } from "Functions/authorize"

const SwitchRouter = ({ useUser, children }) => useUser != null ? <Redirect to="/dashboard" /> : children

SwitchRouter.propTypes = {
  useUser: types.useUser,
  children: types.children
}

const Index = ({ useUser, setUser }) => (
  <SwitchRouter useUser={useUser}>
    <Hero color="info" size="fullheight" gradient>
      <Hero.Body>
        <Container>
          <Heading size={1}>equal</Heading>
          <Heading>
            <Button onClick={(() => { googleLogin(setUser) })} color="primary">Googleアカウントでログイン</Button>
          </Heading>
        </Container>
      </Hero.Body>
    </Hero>
  </SwitchRouter>
)

Index.propTypes = {
  useUser: types.useUser,
  setUser: types.setUser
}

export default withRouter(Index)