import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { request, isNullOrEmpty } from "utility"
import { API_URI_ENDPOINT } from "constructs"

const requestGetGroups = async () => {
  return await request.get({
    uri: API_URI_ENDPOINT.groups
  })
}

const requestGetLinks = async id => {
  return await request.get({
    uri: `${API_URI_ENDPOINT.groups}/${id}/links`
  })
}

const requestDeleteGroup = async id => {
  return await request.delete({
    uri: `${API_URI_ENDPOINT.admin.groups}/${id}`,
  })
}

const requestDeleteLink = async (doc, id) => {
  return await request.delete({
    uri: `${API_URI_ENDPOINT.admin.groups}/${doc}/links/${id}`,
  })
}

const createNewGroupData = json => {
  return {
    id: json.id,
    name: json.data.name,
    color: json.data.color,
    active: json.data.active,
    created_at: json.data.created_at,
    updated_at: json.data.updated_at,
    links: []
  }
}

const createNewLinkData = json => {
  return {
    id: json.id,
    url: json.data.url,
    ogp: json.data.ogp,
    active: json.data.active,
    title: json.data.title,
    created_at: json.data.created_at,
    updated_at: json.data.updated_at
  }
}

const getLinks = async (groupJson, setHandle) => {
  const data = []
  for (let i in groupJson) {
    const group = groupJson[i]
    data.push({
      id: group.id,
      ...group.data,
      links: []
    })
    const json = await requestGetLinks(group.id)
    if (json == null) setHandle(true)
    json.forEach(link => {
      data[i].links.push({
        id: link.id,
        ...link.data,
      })
    })
  }
  return data
}

const getGroups = async (setGroup, setHandle) => {
  const groupJson = await requestGetGroups()
  if (groupJson == null) setHandle(true)
  const groups = await getLinks(groupJson, setHandle)
  setGroup(groups)
}

const useGroups = () => {
  const [useGroup, setGroup] = useState([])
  const [useHandle, setHandle] = useState(false)

  useEffect(() => {
    getGroups(setGroup, setHandle)
  }, [])

  const setDeleteGroup = id => {
    const newGroups = useGroup.filter(obj => obj.id !== id)
    setGroup(newGroups)
  }

  const setDeleteLink = (doc, id) => {
    const index = useGroup.findIndex(obj => obj.id === doc)
    const newLinks = useGroup[index].links.filter(obj => obj.id !== id)
    const copyGroup = [...useGroup]
    copyGroup[index].links = newLinks
    setGroup(copyGroup)
  }

  const handleGroupDelete = async id => {
    if (window.confirm("このグループを削除しますか？グループ内に登録されているリンクも全て削除されます。")) {
      const json = await requestDeleteGroup(id)
      if (isNullOrEmpty(json)) setHandle(true)
      else {
        toast.success("グループを削除しました。")
        setDeleteGroup(json.id)
      }
    }
  }

  const handleLinkDelete = async (doc, id) => {
    if (window.confirm("このリンクを削除しますか？")) {
      const json = await requestDeleteLink(doc, id)
      if (isNullOrEmpty(json)) setHandle(true)
      else {
        toast.success("リンクを削除しました。")
        setDeleteLink(doc, id)
      }
    }
  }

  const handleSetNewGroup = json => {
    const data = createNewGroupData(json)
    const newGroups = [data, ...useGroup]
    setGroup(newGroups)
  }

  const handleSetNewLink = (id, json) => {
    const index = useGroup.findIndex(obj => obj.id === id)
    const data = createNewLinkData(json)
    const copyGroup = [...useGroup]
    copyGroup[index].links = [data, ...copyGroup[index].links]
    setGroup(copyGroup)
  }

  const handleUpdateGroup = json => {
    const id = json.id
    const index = useGroup.findIndex(obj => obj.id === id)
    const copyGroup = [...useGroup]
    copyGroup[index].name = json.data.name
    copyGroup[index].color = json.data.color
    setGroup(copyGroup)
  }

  return [useGroup, useHandle, handleGroupDelete, handleLinkDelete, handleSetNewGroup, handleSetNewLink, handleUpdateGroup]
}

export default useGroups