import React from "react"
import { Navbar } from "react-bulma-components"

const Header = () => (
  <Navbar>
    <Navbar.Brand>
      <Navbar.Item>
        LOGO admin
      </Navbar.Item>
    </Navbar.Brand>
  </Navbar>
)

export default Header